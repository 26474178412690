import {useEffect, useState} from "react";
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox';
import {RoleFormatter} from "../../common/RoleFormatter";
import {LoadingComponent} from "../../common/LoadingComponent";
import {ErrorComponent} from "../../common/ErrorComponent";
import {PinspinClient} from "../../../api/PinspinClient";
import {UserInfoClientDto} from "../../../api/NswagClient";

export function UsersPage() {

    const pageSize = 50;

    const [users, setUsers] = useState<UserInfoClientDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getUsers(0, pageSize)
            .then(x => {
                setUsers(x.items!);
            })
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            {
                !loading && !error &&
                <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                    <List>
                        {
                            users.map(x =>
                                <ListItem key={x.userId} disableGutters disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <InboxIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={x.username}
                                                      secondary={RoleFormatter.format(x.role!)}/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    </List>
                </Box>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>
    );
}