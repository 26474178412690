import {
    ContestClientDto,
    ContestParticipantStatusClientDto,
    ContestStatusClientDto, IAccountClientDto
} from "../../api/NswagClient";

export class ContestUtils {

    static canEdit(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && (contest.status === ContestStatusClientDto.Draft || contest.status === ContestStatusClientDto.Published) &&
            account && account.userId === contest.organizer!.userId;
    }

    static canPublish(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && contest.status === ContestStatusClientDto.Draft &&
            account && account.userId === contest.organizer!.userId;
    }

    static canStart(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && contest.status === ContestStatusClientDto.Published &&
            account && account.userId === contest.organizer!.userId;
    }

    static canCancel(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && (contest.status === ContestStatusClientDto.Draft || contest.status === ContestStatusClientDto.Published) &&
            account && account.userId === contest.organizer!.userId;
    }

    static canReset(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && contest.status === ContestStatusClientDto.Started &&
            account && account.userId === contest.organizer!.userId;
    }

    static canJoin(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && contest.status === ContestStatusClientDto.Published &&
            account && contest.participants!.every(x => x.playerId !== account.userId);
    }

    static canLeave(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && contest.status === ContestStatusClientDto.Published &&
            account && contest.participants!.some(x => x.playerId === account.userId &&
                (x.status === ContestParticipantStatusClientDto.New ||
                    x.status === ContestParticipantStatusClientDto.Accepted ||
                    x.status === ContestParticipantStatusClientDto.Reserved)
            );
    }

    static isPendingJoin(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && contest.status === ContestStatusClientDto.Published &&
            account && contest.participants!.some(x => x.playerId === account.userId && x.status === ContestParticipantStatusClientDto.New);
    }

    static isRejected(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && contest.status === ContestStatusClientDto.Published &&
            account && contest.participants!.some(x => x.playerId === account.userId && x.status === ContestParticipantStatusClientDto.Rejected);
    }

    static isAccepted(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && account && contest.participants?.some(x => x.playerId === account.userId && x.status === ContestParticipantStatusClientDto.Accepted);
    }

    static isReserved(account?: IAccountClientDto, contest?: ContestClientDto) {
        return contest && account && contest.participants?.some(x => x.playerId === account.userId && x.status === ContestParticipantStatusClientDto.Reserved);
    }
}