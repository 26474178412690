import {
    Box, Button,
    List,
    ListItem,
    ListItemButton,
    Paper,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";
import {LoadingComponent} from "../common/LoadingComponent";
import {PinspinClient} from "../../api/PinspinClient";
import {ContestInfoClientDto} from "../../api/NswagClient";
import {SubHeader} from "../common/TitleComponent";
import {format} from "date-fns";
import {ru} from "date-fns/locale";

export function DashboardPage() {

    const cities = useSelector(selectCities);
    const [loading, setLoading] = useState(false);
    const [recentContests, setRecentContests] = useState<ContestInfoClientDto[]>();

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const contestService = new PinspinClient();
                setRecentContests((await contestService.getContests(0, 5)).items!);

            } catch (e) {

            } finally {
                setLoading(false);
            }
        })()
    }, []);

    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));

    return (
        <>

            {
                !loading && recentContests &&

                <Paper elevation={0}>

                    <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                        <SubHeader>
                            Ближайшие турниры
                        </SubHeader>
                    </Box>

                    {
                        recentContests.length > 0 &&
                        <List>
                            {
                                recentContests.map(x =>
                                    <ListItem key={x.contestId} disableGutters disablePadding>
                                        <ListItemButton component={RouterLink} to={`/contests/${x.contestId}`}>
                                            <Box>
                                                <Typography sx={{ color: 'text.primary', fontSize: 16 }} noWrap>
                                                    {x.name?.title}
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary', fontSize: 14 }} noWrap>
                                                    {(cityNames.get(x.locationId!) ?? '') + ', ' + x.address}
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary', fontSize: 14 }} noWrap>
                                                    {format(x.dueDate!, 'd MMMM, HH:mm', { locale: ru })}
                                                </Typography>
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        </List>
                    }

                    {
                        recentContests.length === 0 &&
                        <Box sx={{ m: 2, display: 'flex', gap: 1 }}>
                            <Typography>Пока ни одного турнира,</Typography>
                        </Box>
                    }

                    <Box sx={{ display: 'flex', gap: 1, pl: 1, pr: 1, pb: 1 }}>
                        <Button component={RouterLink} to={`/contests`}>Все турниры</Button>
                    </Box>
                </Paper>
            }

            {
                loading && <LoadingComponent/>
            }
        </>
    )
}