import {Box, Paper} from "@mui/material";
import {useSnackbar} from "notistack";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {PinspinClient} from "../../api/PinspinClient";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";

export function AdminPage() {

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleRecalculatePositions = async () => {
        try {
            setLoading(true);
            await new PinspinClient().recalculatePositions();
            enqueueSnackbar('Запрос отправлен', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            <Box>
                <LoadingButton variant="text" loading={loading} onClick={handleRecalculatePositions}>
                    Пересчитать позиции игроков
                </LoadingButton>
            </Box>
        </Paper>
    )
}