import {useEffect, useState} from "react";
import {format} from "date-fns";
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox';
import {LoadingComponent} from "../../common/LoadingComponent";
import {ErrorComponent} from "../../common/ErrorComponent";
import {PinspinClient} from "../../../api/PinspinClient";
import {JournalInfoClientDto} from "../../../api/NswagClient";

export function JournalsPage() {

    const pageSize = 50;

    const [journals, setJournals] = useState<JournalInfoClientDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getJournals(0, pageSize)
            .then(x => {
                setJournals(x.items!);
            })
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            {
                !loading && !error &&
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <List>
                        {
                            journals.map(x =>
                                <ListItem key={x.journalId} disableGutters disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <InboxIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={x.message}
                                                      secondary={format(x.date!, 'hh:mm dd.MM.yyyy')}/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    </List>
                </Box>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>
    );
}