import {createBrowserRouter, Navigate, Outlet, useLocation} from "react-router-dom";
import AppComponent from "../AppComponent";
import {DashboardPage} from "../features/dashboard/DashboardPage";
import {PlayerListPage} from "../features/player/PlayerListPage";
import {NotFoundPage} from "../features/common/NotFoundPage";
import {AboutPage} from "../features/site/AboutPage";
import {EulaPage} from "../features/site/EulaPage";
import {FaqPage} from "../features/site/FaqPage";
import {RulesPage} from "../features/site/RulesPage";
import {CoachListPage} from "../features/coach/CoachListPage";
import {GymListPage} from "../features/gym/GymListPage";
import {PlayerPage} from "../features/player/PlayerPage";
import {CoachPage} from "../features/coach/CoachPage";
import {GymPage} from "../features/gym/GymPage";
import {ContestListPage} from "../features/contest/ContestListPage";
import {ContestPage} from "../features/contest/view/ContestPage";
import {JournalsPage} from "../features/admin/journaling/JournalsPage";
import {SignInPage} from "../features/account/SignInPage";
import {PasswordRecoveryPage} from "../features/account/PasswordRecoveryPage";
import {SignUpPage} from "../features/account/SignUpPage";
import {PasswordResetPage} from "../features/account/PasswordResetPage";
import {AccountActivationPage} from "../features/account/AccountActivationPage";
import {ProfileEditPage} from "../features/profile/ProfileEditPage";
import {UsersPage} from "../features/admin/user/UsersPage";
import {StorePage} from "../features/store/StorePage";
import {ProfileSettingsPage} from "../features/profile/ProfileSettingsPage";
import {ContestCreatePage} from "../features/contest/ContestCreatePage";
import {useAppSelector} from "../app/hooks";
import {selectAccount} from "../features/account/accountSlice";
import {ContestEditPage} from "../features/contest/ContestEditPage";
import {ContestStartPage} from "../features/contest/start/ContestStartPage";
import {AdminPage} from "../features/admin/AdminPage";
import {AccountRoleClientDto} from "../api/NswagClient";

export const router = createBrowserRouter([
    {
        path: '',
        element: <AppComponent/>,
        errorElement: <NotFoundPage/>,
        children: [
            {
                path: '/',
                element: <DashboardPage/>
            },
            {
                path: 'players',
                element: <PlayerListPage/>
            },
            {
                path: 'players/:name',
                element: <PlayerPage/>
            },
            {
                path: 'coaches',
                element: <CoachListPage/>
            },
            {
                path: 'coaches/:username',
                element: <CoachPage/>
            },
            {
                path: 'gyms',
                element: <GymListPage/>
            },
            {
                path: 'gyms/:username',
                element: <GymPage/>
            },
            {
                path: 'stores/:username',
                element: <StorePage/>
            },
            {
                path: 'contests',
                children: [
                    {
                        path: '',
                        element: <ContestListPage/>
                    },
                    {
                        path: ':id',
                        element: <ContestPage/>
                    },
                    {
                        path: 'create',
                        element: <RequireAuth><ContestCreatePage/></RequireAuth>
                    },
                    {
                        path: ':id/edit',
                        element: <RequireAuth><ContestEditPage/></RequireAuth>
                    },
                    {
                        path: ':id/start',
                        element: <RequireAuth><ContestStartPage/></RequireAuth>
                    }
                ]
            },
            {
                path: 'about',
                element: <AboutPage/>
            },
            {
                path: 'eula',
                element: <EulaPage/>
            },
            {
                path: 'faq',
                element: <FaqPage/>
            },
            {
                path: 'rules',
                element: <RulesPage/>
            },
            {
                path: 'profile',
                children: [
                    {
                        path: 'edit',
                        element: <ProfileEditPage/>
                    },
                    {
                        path: 'settings',
                        element: <ProfileSettingsPage/>
                    }
                ]
            },
            {
                path: 'admin',
                element: <Outlet/>,
                errorElement: <NotFoundPage/>,
                children: [
                    {
                        path: '',
                        element: <RequireAdmin><AdminPage/></RequireAdmin>
                    },
                    {
                        path: 'journals',
                        element: <RequireAdmin><JournalsPage/></RequireAdmin>
                    },
                    {
                        path: 'users',
                        element: <RequireAdmin><UsersPage/></RequireAdmin>
                    }
                ]
            }
        ]
    },
    {
        path: 'account',
        errorElement: <NotFoundPage/>,
        children: [
            {
                path: 'signin',
                element: <SignInPage/>
            },
            {
                path: 'signup',
                element: <SignUpPage/>
            },
            {
                path: 'activate',
                element: <AccountActivationPage/>
            },
            {
                path: 'recovery',
                element: <PasswordRecoveryPage/>
            },
            {
                path: 'reset',
                element: <PasswordResetPage/>
            }
        ]
    }
]);

function RequireAuth({ children }: { children: JSX.Element }) {

    const account = useAppSelector(selectAccount);
    const location = useLocation();

    if (!account) {
        return <Navigate to="/account/signin" state={{ from: location }} replace/>;
    }

    return children;
}

function RequireAdmin({ children }: { children: JSX.Element }) {

    const account = useAppSelector(selectAccount);
    const location = useLocation();

    if (!account || account.role !== AccountRoleClientDto.Admin) {
        return <Navigate to="/account/signin" state={{ from: location }} replace/>;
    }

    return children;
}