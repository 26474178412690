import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography} from "@mui/material";
import {PlayerFormatter} from "./PlayerFormatter";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {PinspinClient} from "../../api/PinspinClient";
import {PlayerInfoClientDto} from "../../api/NswagClient";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";

export function PlayerListPage() {

    const cities = useSelector(selectCities);
    const [players, setPlayers] = useState<PlayerInfoClientDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getPlayers(0, 100)
            .then(x => setPlayers(x.items!))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    const citiesMap = new Map(cities.map(x => [x.cityId, x.name]));

    const formatName = (player: PlayerInfoClientDto) => {
        return PlayerFormatter.formatName(player);
    };

    const formatCity = (player: PlayerInfoClientDto) => {
        if (player.locationId && player.locationId > 0 && citiesMap.has(player.locationId)) {
            return citiesMap.get(player.locationId);
        }
        return '';
    };

    const formatRating = (player: PlayerInfoClientDto) => {
        if (player.rating && player.rating > 0) {
            return player.rating.toFixed(2);
        }
        return '';
    };

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

            {
                !loading && !error &&
                <List>
                    {
                        players.map(x =>
                            <ListItem key={x.username}
                                      disableGutters disablePadding
                                      secondaryAction={<Typography sx={{ pr: 2 }}>{formatRating(x)}</Typography>}
                            >
                                <ListItemButton component={Link} to={`/players/${x.username}`}>
                                    <ListItemAvatar>
                                        <Avatar alt={x.username} src={x.avatar}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={formatName(x)}
                                                  secondary={formatCity(x)}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                </List>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </Box>
    );
}