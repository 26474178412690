import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {LoadingComponent} from "../../common/LoadingComponent";
import {ErrorComponent} from "../../common/ErrorComponent";
import {useSelector} from "react-redux";
import {selectCities} from "../../core/coreSlice";
import {selectAccount} from "../../account/accountSlice";
import {ContestParticipants} from "./ContestParticipants";
import {ContestOrganizers} from "./ContestOrganizers";
import {ContestDescription} from "./ContestDescription";
import {CompetitionTables} from "../competition/CompetitionTables";
import {PinspinClient} from "../../../api/PinspinClient";
import {ContestClientDto, ContestStatusClientDto} from "../../../api/NswagClient";
import {CompetitionMatches} from "../competition/CompetitionMatches";
import {CompetitionStandingsView} from "../competition/CompetitionStandingsView";
import {ContestParticipantActions} from "./ContestParticipantActions";

export function ContestPage() {

    const params = useParams<{ id: string }>();
    const cities = useSelector(selectCities);
    const account = useSelector(selectAccount);
    const [contest, setContest] = useState<ContestClientDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getContest(Number.parseInt(params.id ?? '') ?? '')
            .then(x => setContest(x))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [params.id]);

    const handleContestReload = async () => {
        try {
            if (!contest) {
                return;
            }
            setContest(await new PinspinClient().getContest(contest.contestId!));
        } catch (e) {

        }
    };

    const cityNames = new Map((cities ?? []).map(x => [x.cityId!, x.name!]));

    return (
        <>
            {
                !loading && !error && contest &&
                <>
                    <ContestDescription contest={contest} onChanged={x => setContest(x)}/>

                    {
                        contest.status === ContestStatusClientDto.Published &&
                        <ContestParticipantActions contest={contest} onChanged={x => setContest(x)}/>
                    }

                    <ContestOrganizers contest={contest}/>

                    {
                        (contest.status === ContestStatusClientDto.Draft || contest.status === ContestStatusClientDto.Published) &&
                        <ContestParticipants account={account}
                                             contest={contest}
                                             cities={cityNames}
                                             onAccept={handleContestReload}/>
                    }

                    {
                        contest.status === ContestStatusClientDto.Started &&
                        <>
                            <CompetitionTables contest={contest}
                                               account={account}
                                               onChange={x => setContest(x)}/>

                            <CompetitionMatches contest={contest}
                                                account={account}/>
                        </>
                    }

                    {
                        (contest.status === ContestStatusClientDto.Finished || contest.status === ContestStatusClientDto.Completed || contest.status === ContestStatusClientDto.Recalculating) &&
                        <>
                            <CompetitionStandingsView contest={contest}/>
                            <CompetitionMatches contest={contest}
                                                account={account}/>
                        </>
                    }
                </>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>
    );
}
