import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Avatar, Box, Grid, List, ListItem, ListItemButton, Paper, Typography} from "@mui/material";
import {PlayerFormatter} from "./PlayerFormatter";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";
import {PinspinClient} from "../../api/PinspinClient";
import {PlayerClientDto} from "../../api/NswagClient";
import {TitleComponent} from "../common/TitleComponent";
import {ContestFormatter} from "../contest/ContestFormatter";

export function PlayerPage() {

    const params = useParams<{ name: string }>();
    const cities = useSelector(selectCities);
    const [player, setPlayer] = useState<PlayerClientDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getPlayer(params.name ?? '')
            .then(x => setPlayer(x))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [params.name]);

    const locationsMap = new Map(cities.map(x => [x.cityId, x.name]));

    return (
        <>
            {
                !loading && !error && player &&
                <Box>
                    <Paper elevation={0} sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Avatar src={player.avatar} sx={{ width: 56, height: 56 }} variant='circular'/>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <TitleComponent>
                                    {PlayerFormatter.formatName(player)}
                                </TitleComponent>
                                <Typography>
                                    {locationsMap.get(player.locationId) ?? ''}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>

                    <Box sx={{ mt: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Paper elevation={0} sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                        Игровая рука
                                    </Typography>
                                    <Typography noWrap>
                                        {
                                            PlayerFormatter.formatDominantHand(player.dominantHand!)
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper elevation={0} sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                        Стиль игры
                                    </Typography>
                                    <Typography noWrap>
                                        {
                                            PlayerFormatter.formatPlayingStyle(player.playingStyle!)
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper elevation={0} sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                        Хват ракетки
                                    </Typography>
                                    <Typography noWrap>
                                        {
                                            PlayerFormatter.formatGrip(player.grip!)
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={4}>
                                <Paper elevation={0} sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                        Рейтинг
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography noWrap>
                                            {
                                                player.rating && player.rating > 0 ? `${Math.round(player.rating)}` : '-'
                                            }
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: 12,
                                            ml: 1,
                                            color: `${(player.ratingChange && player.ratingChange! > 0 ? 'success.main' : 'error.main')}`
                                        }} noWrap>
                                            {
                                                player.ratingChange && player.ratingChange! > 0 ? `+${Math.round(player.ratingChange!)}` : ''
                                            }
                                            {
                                                player.ratingChange && player.ratingChange! < 0 ? `${Math.round(player.ratingChange!)}` : ''
                                            }
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper elevation={0} sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                        Место
                                    </Typography>
                                    <Typography noWrap>
                                        {
                                            player.position ? player.position : '-'
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper elevation={0} sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                        Игры
                                    </Typography>
                                    <Typography noWrap>
                                        {
                                            player.matches ? player.matches : '-'
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>


                    {
                        (player.lastMatches?.length ?? 0) > 0 &&
                        <Paper elevation={0} sx={{ mt: 1 }}>
                            <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                    Последние матчи
                                </Typography>
                            </Box>
                            <List>
                                {
                                    player.lastMatches?.map(x =>
                                        <ListItem key={x.matchId} disableGutters disablePadding>
                                            <ListItemButton>
                                                <Box sx={{ flexGrow: 1 }}>

                                                    <Grid container spacing={2} alignItems={'center'}>

                                                        <Grid item xs={5}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: 2,
                                                                alignItems: 'center'
                                                            }}>
                                                                <Avatar sx={{ width: 24, height: 24 }}
                                                                        src={x.player1?.avatar}/>
                                                                <Typography noWrap sx={{ fontSize: 14 }}>
                                                                    {ContestFormatter.formatParticipantNameShort(x.player1!)}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={2}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: 2,
                                                                alignItems: 'stretch',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <Typography noWrap
                                                                            sx={{
                                                                                color: 'text.secondary',
                                                                                fontSize: 14
                                                                            }}>
                                                                    {
                                                                        `${x.player1Score} : ${x.player2Score}`
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: 2,
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-end'
                                                            }}>
                                                                <Typography noWrap sx={{ fontSize: 14 }}>
                                                                    {ContestFormatter.formatParticipantNameShort(x.player2!)}
                                                                </Typography>
                                                                <Avatar sx={{ width: 24, height: 24 }}
                                                                        src={x.player2?.avatar}/>
                                                            </Box>
                                                        </Grid>

                                                    </Grid>

                                                </Box>
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Paper>
                    }

                </Box>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>

    );
}
